import { matchRoutes } from 'react-router-dom'
import {
    initializeFaro,
    createReactRouterV6DataOptions,
    ReactIntegration,
    getWebInstrumentations,
    faro,
    MetaSession // Assuming MetaSession is exported from the library
} from '@grafana/faro-react'

import { TracingInstrumentation } from '@grafana/faro-web-tracing'

// Function to retrieve userId from sessionStorage
const getUserIdFromStorage = (): string | null => {
    return sessionStorage.getItem('userId')
}

// Function to generate a temporary session ID
const generateTemporarySessionId = (): string => {
    return `${Date.now()}-${Math.random().toString(36).substring(2, 10)}`
}

// Function to update session ID with retry (every 1 sec, max 20 sec)
const updateSessionId = async (maxWaitTime = 20000, interval = 1000): Promise<void> => {
    return new Promise(resolve => {
        const startTime = Date.now()

        const checkStorage = (): void => {
            const userId = getUserIdFromStorage()
            if (userId) {
                faro.api?.setSession({ id: userId })
                resolve()
            } else if (Date.now() - startTime < maxWaitTime) {
                setTimeout(checkStorage, interval) // Retry after interval
            } else {
                // User ID not found after retries, keeping the same temporary session ID
                resolve()
            }
        }

        checkStorage()
    })
}

// Function to initialize Faro after waiting for userId
const initializeFaroWithUserId = async (): Promise<void> => {
    try {

        const tempSessionId = generateTemporarySessionId()

        initializeFaro({
            url: process.env.REACT_APP_FARO_URL,
            app: {
                name: process.env.REACT_APP_FARO_PROJECT,
                version: '1.0.0',
                environment: 'production'
            },
            sessionTracking: {
                enabled: true,
                persistent: true,
                generateSessionId: () => tempSessionId, // Set a temp session ID
                onSessionChange: (oldSession: MetaSession | null, newSession: MetaSession) => {
                    console.log('Session changed:', { oldSession, newSession })
                }
            },
            instrumentations: [
                ...getWebInstrumentations(),
                new TracingInstrumentation(),
                new ReactIntegration({
                    router: createReactRouterV6DataOptions({
                        matchRoutes
                    })
                })
            ]
        })
        // Start checking for userId to update session
        void updateSessionId()

    } catch (error) {
        console.error('Error initializing Faro:', error)
    }
}

// Call function to initialize Faro after ensuring userId is available
void initializeFaroWithUserId() // Explicitly marking as ignored to satisfy eslint
