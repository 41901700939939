import Rollbar from 'rollbar'

interface RollbarServiceOptions {
    enabled: boolean
    accessToken: string
    captureUncaught: boolean
    captureUnhandledRejections: boolean
    environment: string
}

class RollbarService {
    readonly rollbar: Rollbar

    constructor (options: RollbarServiceOptions) {
        this.rollbar = new Rollbar(options)
    }

    updatePayload (payload: Rollbar.Payload): void {
        this.rollbar.configure({
            payload: {
                ...this.rollbar.options.payload,
                ...payload
            },
            onSendCallback: (_isUncaught, _args, payload) => {
                if (typeof window !== 'undefined' && 'connection' in navigator) {
                    payload.connectionStatus = {
                        downlink: navigator.connection?.downlink,
                        effectiveType: navigator.connection?.effectiveType,
                        rtt: navigator.connection?.rtt,
                        saveData: navigator.connection?.saveData
                    }
                }
            }
        })
    }
}

const rollbarService = new RollbarService({
    enabled: (process.env.REACT_APP_ENV !== 'local'),
    accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN!,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.REACT_APP_ENV!
})

// Update Rollbar Names To Log
enum RollbarExceptionNames {
    DiagnosticJoinMicError = 'Diagnostic-Join-Mic-Error',
    DiagnosticJoinButtonStatusError = 'Diagnostic-Join-Button-Status-Error',
    JoinSessionError = 'Join-Session-error',
    DiagnosticJoinButtonVideoStreamError = 'Diagnostic-Join-Button-Video-Stream-Error',
    DiagnosticJoinButtonClick = 'Diagnostic-Join-Button-Click',
    DiagnosticMediaDeviceNavigatorError = 'Diagnostic-Error-Navigator-MediaDevices-GetUserMedia',
    DiagnosticNoIssue = 'Diagnostic-No-Issue',
    DiagnosticCameraNotStreaming = 'Diagnostic-Camera-Is-Not-Streaming'
}

export { rollbarService, RollbarExceptionNames }
export default rollbarService.rollbar
