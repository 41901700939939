import { createHashRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from '@mui/material'
import reportWebVitals from 'reportWebVitals'
import { theme } from 'theme'
import { ISpring } from 'contexts/ispring'
import { Assessment } from 'contexts/assessment'
import { Conference } from 'contexts/conference'
import { Booking } from 'contexts/booking'
import { routes } from 'pages/routes'
import 'hello-design-system/dist/hello-design-system.css'
import 'index.css'
import { rollbarService } from 'services/rollbar'
import microsoftClarity from 'services/microsoft-clarity'
import googleTagManager from 'services/google-tag-manager'
import { GBProvider } from 'growthBookProvider'
import 'faroconfig.ts'

rollbarService.updatePayload({
    environment: process.env.REACT_APP_ENV,
    client: {
        javascript: {
            source_map_enabled: true,
            code_version: process.env.REACT_APP_GIT_SHA,
            guess_uncaught_frames: true
        }
    }
})

if (process.env.REACT_APP_MICROSOFT_CLARITY_IS_ENABLED) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = microsoftClarity
    document.head.appendChild(script)
}

if (process.env.REACT_APP_GTM_IS_ENABLED === 'true') {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.innerHTML = googleTagManager
    document.head.appendChild(script)
}

const start = async (): Promise<void> => {
    if (typeof window !== 'undefined' && 'wakeLock' in navigator) {
        try {
            // prevent the screen from turning off on mobile devices or for screensavers to start on desktop
            await navigator.wakeLock.request('screen')
        } catch (e: any) {
            console.log(e.name, e.message)
        }
    }

    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
        <ThemeProvider theme={theme}>
            <Booking>
                <Conference>
                    <Assessment>
                        <ISpring>
                            <GBProvider>
                                <RouterProvider router={createHashRouter(createRoutesFromElements(routes))} />
                            </GBProvider>
                        </ISpring>
                    </Assessment>
                </Conference>
            </Booking>
        </ThemeProvider>
    )
}

if (process.env.REACT_APP_ENV !== 'local') {
    void navigator.serviceWorker.getRegistration().then(reg => {
        if (reg) {
            void reg.unregister().then(reg => {
                location.reload()
            })
        }
    })

    const token = process.env.REACT_APP_SAB_TOKEN
    if (token) {
        const chromeOriginTrialMeta = document.createElement('meta')
        chromeOriginTrialMeta.httpEquiv = 'origin-trial'
        chromeOriginTrialMeta.content = token

        document.querySelector('head')?.prepend(chromeOriginTrialMeta)
        document.domain = 'wallstreetenglish.com'
    }

    void start()

} else if (crossOriginIsolated) {
    void start()

    // Learn more: https://bit.ly/CRA-vitals
    reportWebVitals(vital => console.info(`${vital.name} ${Math.round(vital.value)}ms`)).catch(e => {
        console.error(e)
    })

} else {
    void navigator.serviceWorker.register('sw-f1a3c37f.js', { updateViaCache: 'all' }).then(reg => {
        if (reg.installing) {
            reg.installing.addEventListener('statechange', (ev: any) => {
                if (ev.target?.state) {
                    location.reload()
                }
            })
        }
    })

    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render('')
}
